import {
  useState,
  useEffect,
  useContext,
  createContext
} from 'react';

const prefersColorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

function _usePrefersColorScheme() {
  const [prefersColorScheme, setPrefersColorScheme] = useState(prefersColorSchemeQuery.matches ? 'dark' : 'light');
  useEffect(() => {
    const callback = e => {
      setPrefersColorScheme(e.matches ? 'dark' : 'light');
    };
    prefersColorSchemeQuery.addEventListener('change', callback);
    return () => prefersColorSchemeQuery.removeEventListener('change', callback);
  }, []);
  return [prefersColorScheme];
}

const PrefersColorSchemeContext = createContext(undefined);
export function PrefersColorSchemeProvider({
  children
}) {
  const [prefersColorScheme] = _usePrefersColorScheme();
  return ( 
    <PrefersColorSchemeContext.Provider value = {
      prefersColorScheme
    }> 
      {children} 
    </PrefersColorSchemeContext.Provider>
  );
}

export function usePrefersColorScheme() {
  return {
    prefersColorScheme: useContext(PrefersColorSchemeContext)
  };
}