export default Object.freeze([{
  name: 'EasyClicker',
  path: 'easyclicker',
  title: 'easy_clicker_app_title',
  subtitle: 'easy_clicker_app_subtitle',
  favicon: '/res/easyclicker/easyclicker@favicon.png',
  mainicon: '/res/easyclicker/easyclicker.png',
  download: {
    'iOS': 'https://apps.apple.com/app/id1599691715',
    'macOS': 'https://apps.apple.com/app/id1599691715'
  },
},
{
  name: 'ImageX',
  path: 'imagex',
  title: 'imagex_app_title',
  subtitle: 'imagex_app_subtitle',
  favicon: '/res/imagex/imagex@favicon.png',
  mainicon: '/res/imagex/imagex.png',
  download: {
    'iOS': 'https://apps.apple.com/app/id1668530080',
    'macOS': 'https://apps.apple.com/app/id1668530080'
  },
},
// {
//   name: 'Out of Battery',
//   path: 'outofbattery',
//   title: 'out_of_battery_app_title',
//   subtitle: 'out_of_battery_app_subtitle',
//   favicon: '/res/outofbattery/outofbattery@favicon.png',
//   mainicon: '/res/outofbattery/outofbattery.png',
//   download: {
//     'iOS': 'https://apps.apple.com/app/id6451079132'
//   },
// }
]);