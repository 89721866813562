import React, { useEffect } from 'react';
import styles from './index.module.css';
// import apps from '../../constants/apps';

export default function Home({ contacts = true }) {
  useEffect(() => {
    if (contacts !== true) {
      return;
    }
    const contactsLabel = document.getElementsByClassName(styles.xxx)[0];
    if (contactsLabel !== undefined) {
      const em = 'support'
        + String.fromCharCode(64)
        + 'tinyperfectapps'
        + String.fromCharCode(46)
        + 'com';
      contactsLabel.innerHTML = em.split('').join('<span> x </span>');
    }
  }, [contacts]);
  return <>
    <div className={styles.background}>
      <div className={styles.studio_name}>TinyPerfectApps</div>
      {
        contacts
          ? <div className={styles.xxx} />
          : undefined
      }
      {/* <div className={styles.app_icon_container}>
        {
          apps.map(app => <img className={styles.app_icon} src={app.icon} />)
        }
      </div> */}
    </div>
  </>;
}
