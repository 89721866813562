import LanguageDetector from 'i18next-browser-languagedetector';
import { use } from 'i18next';
import en from './lang/en.json';
import zhHans from './lang/zh-Hans.json';
import zhHant from './lang/zh-Hant.json';
import {
  initReactI18next
} from 'react-i18next';

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zhHans,
      },
      'zh-TW': {
        translation: zhHant,
      },
    },
    fallbackLng: {
      'default': ['en']
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['sessionStorage']
    },
  }).then(() => {});
