import styles from './index.module.css';
import { platforms } from '../../constants/platforms';
import { usePrefersColorScheme } from '../../hooks/useColorScheme';

export default function DownloadGroup({ app }) {
  const { download } = app;
  return <div className={styles.download_group}>
    {
      download.iOS && <DownloadButton platform={platforms.iOS} href={download.iOS} />
    }
    {
      download.macOS && <DownloadButton platform={platforms.macOS} href={download.macOS} />
    }
  </div>;
}

const DownloadButton = ({ platform, href }) => {
  const { prefersColorScheme } = usePrefersColorScheme();
  const isDark = prefersColorScheme === 'dark';
  let imgSrc;
  switch (platform) {
  case platforms.iOS:
    imgSrc = `/static/image/app_store_${isDark ? 'dark' : 'light'}.svg`;
    break;
  case platforms.macOS:
    imgSrc = `/static/image/mac_app_store_${isDark ? 'dark' : 'light'}.svg`;
    break;
  default: return null;
  }
  return <a key={platform} href={href}>
    {
      <img src={imgSrc} className={styles.download_button} />
    }
  </a>;
};
  