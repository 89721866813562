import React, { useEffect } from 'react';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';
import DownloadGroup from './DownloadGroup';

export default function AppBoard({ app }) {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = app.name;
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = app.favicon;
  }, [app.name]); // eslint-disable-line react-hooks/exhaustive-deps
  return <div className={styles.app_board}>
    <img className={styles.app_icon} src={app.mainicon} />
    <p className={styles.app_title}>{t(app.title)}</p>
    <p className={styles.app_subtitle}>{t(app.subtitle)}</p>
    <DownloadGroup app={app} />
  </div>;
}
