import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
// import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
// import { useColorScheme } from '@mantine/hooks';
import {
  BrowserRouter as Router,
  useParams,
  Route,
  Routes,
} from 'react-router-dom';
import './index.css';
import './i18n';
import RedirectToHome from './components/RedirectToHome';
import Copyright from './components/Copyright';
import Home from './pages/Home';
import AppBoard from './pages/AppBoard';
import reportWebVitals from './reportWebVitals';
import apps from './constants/apps';
import { PrefersColorSchemeProvider } from './hooks/useColorScheme';

const Apps = () => {
  const { appName = '' } = useParams();
  const app = apps.find(app => app.path.toLowerCase() === appName.toLowerCase());
  if (app === undefined) return <RedirectToHome />;
  return <AppBoard app={app} />;
};

const CompatiblePolicy = () => {
  const { policy = '' } = useParams();
  useEffect(() => {
    window.location.href = `/res/easyclicker/policy/${policy}`;
  }, [policy]);
  return <p />;
};

const Index = () => {
  return (
    <PrefersColorSchemeProvider>
      <Router>
        <div className='router_container'>
          <Routes>
            <Route path='*' element={<RedirectToHome />} />
            <Route path='/' element={<Home contacts={false} />} />
            <Route path='/about' element={<Home contacts />} />
            <Route path='/:appName' element={<Apps />} />
            <Route path='/res/policy/:policy' element={<CompatiblePolicy />} />
          </Routes>
        </div>
        <Copyright />
      </Router>
    </PrefersColorSchemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
